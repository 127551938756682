import React from "react";
import { Layout } from "../components/layout/layout";
import { Link } from "gatsby";

import { Breadcrumbs } from "../components/breadcrumbs/breadcrumbs";
import { ProductCategorySection } from "../components/section/productCategorySection";

const ProductPage = () => {
  return (
    <Layout title="製品案内 | LIME" jpUrl="/product/" enUrl="/en/product/">
      <Breadcrumbs>
        <Link to="/">ホーム</Link>
        <span>/</span>
        <Link to="/product/">製品案内</Link>
        <span>/</span>
      </Breadcrumbs>
      <ProductCategorySection visible="hidden" />
    </Layout>
  );
};

export default ProductPage;
